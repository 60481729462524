import {Component, OnInit} from '@angular/core';
import {JobItems} from './jobs';

@Component({
  selector: 'app-jobs-detail',
  templateUrl: './jobs-detail.component.html',
  styleUrls: ['./jobs-detail.component.scss']
})
export class JobsDetailComponent implements OnInit {

  items = JobItems;

  constructor() {
  }

  ngOnInit(): void {
  }

}
