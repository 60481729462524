export const TEXTSERVICES = [{
  label: 'รับจดทะเบียนบริษัท ห้างหุ้นส่วน (หจก.)'
}, {
  label: 'รับทำบัญชี'
}, {
  label: 'วางแผนภาษี'
}, {
  label: 'ปิดงบ'
}, {
  label: 'จด VAT'
}, {
  label: 'ภาษีเงินได้นิติบุคคล'
}, {
  label: 'ภาษีมูลค่าเพิ่ม'
}, {
  label: 'โปรแกรมเงินเดือนพนักงาน payroll'
}, {
  label: 'รับทำเงินเดือน'
}, {
  label: 'ขึ้นทะเบียนประกันสังคม'
}, {
  label: 'จดทะเบียนเลิกบริษัทและชำระบัญชี'
}, {
  label: 'จดทะเบียนพาณิชย์'
}, {
  label: 'ให้คำปรึกษากรณีโดนภาษีย้อนหลัง'
}];
