<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row d-flex justify-content-center">
                <div class="col-12 p-0">
                    <h2 class="h2 w-100 text-center mb-10px">เจ้าของธุรกิจ จะติดกระดุมเม็ดแรกได้ถูกต้อง เมื่อมีที่ปรึกษาที่เข้าใจธุรกิจอยู่เคียงข้าง</h2>
                    <p class="p2 text-center sub-text text-gray-medium">
                        SME ทุกรายต้องการทำธุรกิจให้ประสบความสำเร็จ เดินหน้าได้เต็มที่ แต่ความกังวลเรื่องกฎหมาย บัญชี
                        และภาษี ทำให้ SME ต้องห่วงหน้าพะวงหลัง iTAX sme มีนักบัญชีเฉพาะทางที่เข้าใจธุรกิจของคุณ
                        จะช่วยให้ SME เดินไปข้างหน้าได้ด้วยความสบายใจ
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
