<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row d-flex justify-content-center">
                <div class="col-12 p-0">
                    <h2 class="h2 w-100 text-center mb-10px">ครั้งแรกที่บัญชีธนาคารจะจัดการภาษีให้แม่ค้าออนไลน์เอง</h2>
                    <p class="p2 text-center sub-text text-gray-medium">
                        iTAX bnk ถูกออกแบบมาเพื่อแก้ปัญหาพ่อค้าแม่ค้าออนไลน์ที่ไม่มีความรู้เรื่องภาษีโดยเฉพาะ
                        เพื่อให้ทุกคนสามารถทำธุรกิจออนไลน์ได้ตามปกติและไม่มีความเสี่ยงถูกประเมินภาษีย้อนหลัง เพราะ iTAX
                        bnk จะอาสาทำหน้าที่จัดการภาษีให้คุณเอง เริ่มต้นได้ง่ายๆ ใน 3 ขั้นตอน
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
