<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row d-flex justify-content-center">
                <div class="col-12 p-0">
                    <h2 class="h2 w-100 text-center mb-10px">iTAX sme ให้บริการอะไรได้บ้าง</h2>
                    <p class="p2 text-center sub-text text-gray-medium mb-30px">
                        iTAX sme คือ บริการให้คำปรึกษาทางด้านภาษีธุรกิจแบบครบวงจรสำหรับเจ้าของธุรกิจ
                    </p>
                    <div class="mb-30px">
                        <div class="mb-10px d-flex justify-content-center wrap-item" *ngFor="let item of textServices">
                            <div class="w-100 position-relative">
                                <div class="position-absolute text-primary">
                                    <i class="fas fa-check-circle"></i>
                                </div>
                                <div class="wrap-detail">
                                    {{item.label}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p1 w-100 text-center mb-20px font-weight-bold">
                        ติดต่อขอรับบริการวางแผนภาษี จดบริษัท หานักบัญชี<br/>
                        ค่าบริการเริ่มต้นที่ 5,000 บาท
                    </div>
                    <a class="h3 font-weight-bold text-primary d-flex align-items-center justify-content-center"
                       href="tel:062-486-9787">
                        <div class="text-primary phone">
                            <i class="fas fa-phone"></i>
                        </div>
                        <span class="ml-20px">062-486-9787</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
