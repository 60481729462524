import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {SocialTagsService} from '../../../core/services/social-tags-service.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  readonly tagSocialTitle: string = 'Find Jobs at iTAX - Flat. Flexible. Focus.';
  readonly tagSocialDescription: string = 'สมัครงานกับ iTAX ทำงานยืดหยุ่นแบบ Startup โฟกัสเป้าหมายชัดเจน';
  readonly urlPrefix: string = environment.mainBaseUrl;

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    private tagsService: SocialTagsService
  ) {
    this.title.setTitle('Find Jobs at iTAX - Flat. Flexible. Focus.');
    this.meta.updateTag({
      name: 'title',
      content: 'Find Jobs at iTAX - Flat. Flexible. Focus.'
    });
    this.meta.updateTag({
      name: 'description',
      content: 'สมัครงานกับ iTAX ในตำแหน่ง Software Engineer, Mobile Developer, Business Development และอื่นๆ ทำงานยืดหยุ่นแบบ Startup โฟกัสเป้าหมายชัดเจน'
    });
    this.tagsService.setTitleAndTags({
      tagSocialDescription: this.tagSocialDescription,
      tagSocialTitle: this.tagSocialTitle,
      urlPrefix: this.urlPrefix
    });
  }

  ngOnInit(): void {
  }

}
