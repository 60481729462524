import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sme',
  templateUrl: './sme.component.html',
  styleUrls: ['./sme.component.scss']
})
export class SmeComponent implements OnInit {

  constructor(
      private meta: Meta,
      private title: Title,
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('บริการให้คำปรึกษาด้านภาษี เพื่อธุรกิจ SME โดยเฉพาะ - iTAX');
    this.meta.updateTag({
      name: 'title',
      content: 'บริการให้คำปรึกษาด้านภาษี เพื่อธุรกิจ SME โดยเฉพาะ - iTAX'
    });
    this.meta.updateTag({
      name: 'description',
      content: 'iTAX ที่ปรึกษาด้านภาษี สำหรับธุรกิจ SME ทุกประเภทแบบครบวงจร กับทีมงานมืออาชีพ และมีประสบการณ์โดยตรง สอบถามรายละเอียดเพิ่มเติม 062-486-9787'
    });
  }

}
