import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './main.component';
import {MainDetailComponent} from './components/main-detail/main-detail.component';
import {MainRoutingModule} from './main-routing.module';
import { SubDetailComponent } from './components/sub-detail/sub-detail.component';
import { ItaxAppComponent } from './components/itax-app/itax-app.component';
import { ItaxMarketComponent } from './components/itax-market/itax-market.component';
import { ItaxOtherProductsComponent } from './components/itax-other-products/itax-other-products.component';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { IconModule } from 'src/app/shared/components/icon/icon.module';


@NgModule({
  declarations: [
    MainComponent,
    MainDetailComponent,
    SubDetailComponent,
    ItaxAppComponent,
    ItaxMarketComponent,
    ItaxOtherProductsComponent,
    EventDetailComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    IconModule,
  ],
  exports: [MainComponent]
})
export class MainModule {
}
