import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-main-detail',
  templateUrl: './main-detail.component.html',
  styleUrls: ['./main-detail.component.scss']
})
export class MainDetailComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  registerBnk(): void {
    window.open('https://forms.gle/2GN5hrQPSPYpABneA', '_blank');
  }

}
