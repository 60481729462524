import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'app-itax-other-products',
  templateUrl: './itax-other-products.component.html',
  styleUrls: ['./itax-other-products.component.scss']
})
export class ItaxOtherProductsComponent implements OnInit {

  constructor(
      private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  goto(link: string): void {
    window.open(`${ environment.mainBaseUrl }/${ link }`, '_self');
  }
}
