export interface Detail {
  title: string;
  detail: string[];
}

export interface Item {
  value: any;
  title: string;
  subTitle: string;
  details: Detail[];
}

export const JobItems: Item[] = [{
  value: 'Mobile',
  title: 'Senior Mobile Developer - Flutter',
  subTitle: 'Where craftsmanship meets function.',
  details: [{
    title: 'Mobile Developer Responsibilities:',
    detail: [
      'Developing front-end mobile architecture',
      'Designing user interactions on mobile pages',
      'Ensuring responsiveness of mobile applications',
      'Working alongside UX/UI designers for mobile design features',
      'Seeing through a project from conception to finished product',
      'Meeting both technical and consumer needs'
    ]
  }, {
    title: 'Mobile Developer Requirements:',
    detail: [
      'BS in Computer Science or Computer Engineering or equivalent experience',
      'At least 2-year experience in mobile applications development',
      'At least 1-year experience in mobile applications development with Flutter',
      'Experienced in mobile application development language such as Flutter, React-Native, Kotlin, Swift, or Objective-C',
      'Comfortable with GIT or other source control software',
      'Enjoy working on challenging solutions and systems with a startup mindset and attitude',
      'Teamwork skills with a problem-solving attitude'
    ]
  }]
}, {
  value: 'FullStack',
  title: 'Full Stack Developer',
  subTitle: 'Where the magic happens.',
  details: [{
    title: 'Full Stack Developer Responsibilities:',
    detail: [
      'Developing front-end website architecture',
      'Designing user interactions on web pages',
      'Developing back-end website applications',
      'Creating servers and databases for functionality',
      'Ensuring responsiveness of applications',
      'Working alongside UX/UI designers for website design features',
      'Seeing through a project from conception to finished product',
      'Designing and developing APIs',
      'Meeting both technical and consumer needs'
    ]
  }, {
    title: 'Full Stack Developer Requirements:',
    detail: [
      'BS in Computer Science or Computer Engineering or equivalent experience',
      'At least 3 year-experience in back-end website development',
      'Excellent skill with front-end website programing (HTML5, CSS3, JavaScript, AJAX, JQuery, Bootstrap, Responsive website)',
      'Excellent skills with back-end website programing using Golang',
      'Experience in developing database with MSSQL, MySql, PostgreSQL',
      'Experience in Ruby on Rails will be a plus',
      'Experience in developing website with Vue will be a plus',
      'Comfortable with GIT or other source control software',
      'Enjoy working on challenging solutions and systems with startup mindset and attitude',
      'Teamwork skills with a problem-solving attitude'
    ]
  }]
}, {
  value: 'Backend',
  title: 'Backend Developer',
  subTitle: 'Behind every great product, there\'s a great backend.',
  details: [{
    title: 'Backend Developer Responsibilities:',
    detail: [
      'Developing back-end website applications',
      'Creating servers and databases for functionality',
      'Ensuring responsiveness of applications',
      'Seeing through a project from conception to finished product',
      'Designing and developing APIs',
      'Meeting both technical and consumer needs'
    ]
  }, {
    title: 'Backend Developer Requirements:',
    detail: [
      'BS in Computer Science or Computer Engineering or equivalent experience',
      'At least 3-year experience in backend web development',
      'Excellent skills with back-end web programing using Golang',
      'Experience in developing database with MSSQL, MySql, PostgreSQL',
      'Experience in Ruby on Rails will be a plus',
      'Comfortable with GIT or other source control software',
      'Enjoy working on challenging solutions and systems with startup mindset and attitude',
      'Teamwork skills with a problem-solving attitude'
    ]
  }]
}];
