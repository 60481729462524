import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-bnk-footer',
  templateUrl: './bnk-footer.component.html',
  styleUrls: ['./bnk-footer.component.scss']
})
export class BnkFooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  registerBnk(): void {
    window.open('https://forms.gle/2GN5hrQPSPYpABneA', '_blank');
  }

}
