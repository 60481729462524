import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-culture-detail',
  templateUrl: './culture-detail.component.html',
  styleUrls: ['./culture-detail.component.scss']
})
export class CultureDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
