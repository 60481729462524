<div class="box">
  <div class="itax-body">
    <div class="wrap-img">
      <figure class="bg-img"></figure>
    </div>
    <div class="container position-relative w-100 h-100">
      <div class="row">
        <div class="col-12 position-relative">
          <h1 class="h1 w-100 text-center mb-10px title">Jobs at iTAX</h1>
          <p class="p1 font-weight-bold w-100 text-center mb-30px">
            Flat. Flexible. Focus.
          </p>
          <div class="d-flex justify-content-center">
            <button class="btn btn-blue py-10px join-btn" (click)="gotoView()">Join iTAX team</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
