import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bnk',
  templateUrl: './bnk.component.html',
  styleUrls: ['./bnk.component.scss']
})
export class BnkComponent implements OnInit {

  constructor(
      private meta: Meta,
      private title: Title,
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('iTAX bnk บัญชีธนาคารสำหรับพ่อค้า แม่ค้าออนไลน์ หมดกังวลเรื่องภาษี');
    this.meta.updateTag({
      name: 'title',
      content: 'iTAX bnk บัญชีธนาคารสำหรับพ่อค้า แม่ค้าออนไลน์ หมดกังวลเรื่องภาษี'
    });
    this.meta.updateTag({
      name: 'description',
      content: 'iTAX bnk บัญชีธนาคารสำหรับพ่อค้า แม่ค้าออนไลน์ ช่วยจัดการภาษีที่ยุ่งยากด้วยระบบอัตโนมัติ'
    });
  }

}
