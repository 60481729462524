import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {


  constructor(
      private meta: Meta,
      private title: Title,
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('iTAX บริการด้านภาษี การลงทุน การประกัน ด้วยผู้เชี่ยวชาญ');
    this.meta.updateTag({
      name: 'title',
      content: 'iTAX บริการด้านภาษี การลงทุน การประกัน ด้วยผู้เชี่ยวชาญ'
    });
    this.meta.updateTag({
      name: 'description',
      content: 'iTAX บริการภาษีครบวงจร ตั้งแต่ วางแผนภาษี ประกัน การลงทุน พร้อมเปรียบเทียบผลิตภัณฑ์ลดหย่อนภาษี'
    });
  }
}
