import {Component, OnInit} from '@angular/core';
import {TEXTSERVICES} from './smeService';

@Component({
  selector: 'app-sme-services',
  templateUrl: './sme-services.component.html',
  styleUrls: ['./sme-services.component.scss']
})
export class SmeServicesComponent implements OnInit {

  textServices = TEXTSERVICES;

  constructor() {
  }

  ngOnInit(): void {
  }

}
