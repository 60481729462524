<div class="box">
    <div class="itax-body">
        <div class="wrap-img">
            <figure class="bg-img"></figure>
        </div>
        <div class="container position-relative w-100 h-100">
            <div class="row">
                <div class="col-12 position-relative">
                    <h1 class="h1 w-100 text-center mb-10px text-white title">iTAX bnk บัญชีธนาคารเพื่อแม่ค้าออนไลน์</h1>
                    <p class="p1 font-weight-bold w-100 text-center mb-30px text-white">
                        หมดห่วงเรื่องภาษีย้อนหลัง
                    </p>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-primary join-btn" (click)="registerBnk()">สมัคร iTAX bnk</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
