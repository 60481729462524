import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-detail',
  templateUrl: './header-detail.component.html',
  styleUrls: ['./header-detail.component.scss']
})
export class HeaderDetailComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  gotoView() {
    document.getElementById('job-opening').scrollIntoView({behavior: 'smooth'});
  }

}
