<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="wrap-img">
                <figure class="bg-img"></figure>
            </div>
            <div class="row">
                <div class="col-12 position-relative p-0">
                    <h1 class="h1 w-100 text-center mb-10px">iTAX sme ที่ปรึกษาภาษี เพื่อธุรกิจ SME โดยเฉพาะ</h1>
                    <p class="p1 font-weight-bold w-100 text-center mb-30px">
                        ดูแลด้านกฎหมาย ภาษี และบัญชีครบวงจร
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
