import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-detail',
  templateUrl: './header-detail.component.html',
  styleUrls: ['./header-detail.component.scss']
})
export class HeaderDetailComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  gotoAppPlay(): void {
    window.open('//bit.ly/2ugrUeM', '_blank');
  }

  gotoApp(): void {
    window.open('//apple.co/2sPConn', '_blank');
  }
}
