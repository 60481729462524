<div class="box" id="job-opening">
  <div class="itax-body">
    <div class="container position-relative w-100 h-100">
      <div class="row d-flex justify-content-center">
        <div class="col-12 p-0">
          <h2 class="h2 w-100 text-center mb-10px">Job openings for you</h2>
          <div class="card last-mobile" *ngFor="let item of items">
            <div class="row w-100">
              <div class="col p-0">
                <div class="wrap-content">
                  <span class="content-title">{{item.title}}</span>
                  <span class="content-sub-title">{{item.subTitle}}</span>
                </div>
                <div *ngIf="item.value" class="wrap-btn-explore" data-toggle="collapse" (click)="item.value = !item.value"
                     attr.aria-expanded="{{item.value}}" aria-controls="collapseBasic">
                  <button class="btn btn-primary py-10px join-btn w-100">Explore</button>
                </div>
              </div>
            </div>
            <!-- job detail -->
            <div class="row" [collapse]="item.value"
                 id="{{item.value}}" [isAnimated]="true">
              <div class="row w-100" *ngFor="let detail of item.details">
                <div class="col p-0">
                  <div class="wrap-detail">
                    <span class="font-weight-bold mb-10px">{{detail.title}}</span>
                    <span>
                    <ul class="mb-0">
                      <li class="mb-10px text-gray-medium" *ngFor="let i of detail.detail">{{i}}</li>
                    </ul>
                  </span>
                  </div>
                </div>
              </div>
              <div class="px-20px pb-20px">
                To apply for this position, send your resume and a brief introduction of yourself to
                <a class="text-primary font-weight-bold" href="mailto: jobs@itax.in.th">jobs@itax.in.th</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
