import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
      private meta: Meta,
      private title: Title,
  ) {
  }

  ngOnInit(): void {
    this.title.setTitle('โปรแกรมคำนวณภาษี - iTAX');
    this.meta.updateTag({
      name: 'title',
      content: 'โปรแกรมคำนวณภาษี - iTAX'
    });
    this.meta.updateTag({
      name: 'description',
      content: 'คำนวณภาษี วางแผนภาษี ลดหย่อนภาษีสูงสุดด้วย iTAX รองรับเงินได้ทุกประเภท อัพเดตค่าลดหย่อนล่าสุด'
    });
  }

}
