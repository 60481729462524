<div class="box">
  <div class="itax-body">
    <div class="container position-relative w-100 h-100">
      <div class="row d-flex justify-content-center">
        <div class="col-10 p-0">
          <h2 class="h2 w-100 text-center mb-10px">Join iTAX. Redefine tax together.</h2>
          <p class="p2 text-center sub-text text-gray-medium pb-20px">
            We honor every taxpayer—The real hero for the nation. Taxpayers need and deserve simple and intuitive tax
            services that make sense to them.
          </p>
          <p class="p2 text-center sub-text text-gray-medium pb-20px">
            At iTAX, we have a clear important mission—Simplify everything about tax for every taxpayer. So all of us
            don’t need to know tax to do the tax anymore. We want taxpayers to win this together.
          </p>
          <p class="p2 text-center sub-text text-gray-medium">
            Tax + Technology + Design = iTAX
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
