<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row d-flex justify-content-center">
                <div class="col-12 p-0">
                    <h2 class="h2 w-100 text-center mb-30px title">สนใจเปิดบัญชีธนาคาร iTAX bnk<br/>(รับจำนวนจำกัด)</h2>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-primary py-10px join-btn" (click)="registerBnk()">สมัคร iTAX bnk</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
