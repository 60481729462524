import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bnk-detail',
  templateUrl: './bnk-detail.component.html',
  styleUrls: ['./bnk-detail.component.scss']
})
export class BnkDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
